require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

import '../stylesheets/devise';
import 'bootstrap/dist/js/bootstrap';

$(document).on('turbolinks:load', function() {
});
